import React from "react"

import Separator from "./separator"
import { rhythm } from "../utils/typography"

const yesTopics = [
  "dealing with the unknown",
  "moving and making changes in your life",
  "making friends and building community",
  "imposter syndrome / gaining self-confidence",
  "learning how to advocate for yourself",
  "learning how to set and enforce healthy boundaries, even when it feels uncomfortable",
  "the tech industry",
  "work-life balance",
  "finding space for Christian faith and queer affirmation as an ally",
]

const noTopics = [
  {
    subject: "Questions best answered from an LGBTQ+ and/or POC perspective",
    blogs: [
      {
        title: "¡Hola Papi!",
        subtitle: "by John Paul Brammer",
        link: "https://holapapi.substack.com/",
      },
      {
        title: "Dear Prudence",
        subtitle: "by Daniel Lavery at Slate",
        link: "https://slate.com/human-interest/dear-prudence",
      },
      {
        title: "Sip on This",
        subtitle: "by Ashley Nicole Black at DAME",
        link: "https://www.damemagazine.com/tag/sip-on-this/",
      },
      {
        title: "Asking Bear",
        subtitle: "by S. Bear Bergman",
        link: "http://askingbear.com/",
      },
    ],
  },
  {
    subject: "Trauma, abuse, or mental health questions",
    blogs: [
      {
        title: "Captain Awkward",
        link: "https://captainawkward.com/",
      },
    ],
  },
  {
    subject: "Sex stuff",
    blogs: [
      {
        title: "How to Do It",
        subtitle: "by Stoya and Rich Juzwiak at Slate",
        link: "https://slate.com/human-interest/how-to-do-it",
      },
    ],
  },
  {
    subject: "BONUS: Other columns I love that might be more your style",
    blogs: [
      {
        title: "Ask a Fuck-Up",
        subtitle: "by Brandy Jensen at The Outline",
        link: "https://theoutline.com/contributor/29/brandy-jensen",
      },
      {
        title: "Ask a Manager",
        subtitle: "by Alison Green",
        link: "https://www.askamanager.org/",
      },
    ],
  },
]

function Topics() {
  return (
    <React.Fragment>
      <h3>👍 Topics I'd love to talk about</h3>
      Not an exhaustive list, just some ideas of the kinds of things I'm already
      thinking about!
      <ul>
        {yesTopics.map(topic => (
          <li key={topic}>{topic}</li>
        ))}
      </ul>
      <Separator />
      <h3>
        👎 Topics I'm <em>not</em> comfortable covering
      </h3>
      This is the kind of content that's not really in my wheelhouse—but I'm
      including suggestions of columns that I love that might be better suited!
      {noTopics.map(({ subject, blogs }) => (
        <div
          key={subject}
          style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}
        >
          <strong>{subject}</strong>
          <ul>
            {blogs.map(({ title, subtitle, link }) => (
              <li key={link}>
                <a href={link}>{title}</a> {subtitle}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </React.Fragment>
  )
}

export default Topics
