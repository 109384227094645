import React from "react"
import { graphql, navigateTo } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Separator from "../components/separator"
import Topics from "../components/topics"

const defaultQuestion = "Dear Marie,\n\n"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Ask extends React.Component {
  state = {}

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Ask Dear Marie" keywords={[`blog`]} />
        <p>Hey y'all! 👋</p>
        <p>
          This is Marie, your friendly neighborhood advice column aficionado
          turned aspiring amateur.
        </p>
        <p>Thanks for submitting a question! But first...</p>
        <Separator />
        <Topics />
        <Separator />
        <h3>💬 Now ask away!</h3>
        <form
          name="question"
          method="post"
          action="/thanks"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="question" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <p>
            <label>
              The Short Version
              <br />
              <small>
                The one-sentence summary and/or title! Bonus points for emojis
                💃
              </small>
              <br />
              <input
                className="input"
                type="text"
                name="title"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              The Long Version
              <br />
              <textarea
                className="input"
                name="message"
                rows="10"
                defaultValue={defaultQuestion}
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              (Optional) Contact Information
              <br />
              <small>
                Can be an email address or a Twitter handle! You only have to
                provide this if you'd like me to be able to follow up with
                questions, or let you know personally if I answer your question.
                I will <strong>not</strong> publish this information.
              </small>
              <br />
              <input
                className="input"
                type="text"
                name="contact"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <small>
              I may lightly edit your question for clarity and brevity.
            </small>
          </p>

          <p>
            <button type="submit">Submit</button>
          </p>
        </form>
      </Layout>
    )
  }
}

export default Ask

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
