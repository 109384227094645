import React from "react"

import { rhythm } from "../utils/typography"

function Separator() {
  return (
    <hr
      style={{
        marginBottom: rhythm(2),
        marginTop: rhythm(2),
      }}
    />
  )
}

export default Separator
